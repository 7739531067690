import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
// import parse from 'html-react-parser';
import Helmet from 'react-helmet';
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../assets/styles/blog.scss";


const Blog = ({ data, pageContext }) => {

  const entries = data.craft.entries;
  const tags = data.craft.tags;
  const [loaded, setLoaded] = useState(false);
  const filteredTag = pageContext.tag;
  const filteredTagId = pageContext.tagId;
  const Pager = ({ pageContext }) => {
    const { previousPagePath, nextPagePath } = pageContext;
    return (
      <nav className="pagination" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {previousPagePath && (
            <Link to={previousPagePath}>
              <button>← Newer Posts</button>
            </Link>
          )}
        </div>

        <div style={{ justifySelf: 'flex-end' }}>
          {nextPagePath && (
            <Link to={nextPagePath}>
              <button>Older Posts →</button>
            </Link>
          )}
        </div>
      </nav>
    );

  }
  const Item = ({ entry }, i) => {
    return (
      <div key={i} className="item">
        <h2><Link className="link" to={entry.externalLink ? entry.externalLink : `/blog/${entry.slug}`}>{entry.title}</Link></h2>
        {entry.listingImage && entry.listingImage.length > 0 &&
          <div className="image">
            <img src={entry.listingImage[0].url} alt="" />
          </div>
        }
        {entry.blogSummary}
        {entry.tags && entry.tags.length > 0 &&
          <ul className="tags">
            {entry.tags.map((tag, i) => {
              return (
                <li key={i}><Link to={'/blog/tag/' + tag.slug} className={`link ${tag.id && tag.id.toString() === filteredTagId && filteredTagId.toString() ? 'link--active' : ''}`}>#{tag.title}</Link></li>
              )
            })}
          </ul>
        }
      </div>
    )
  }
  useEffect(() => {
    // if(typeof window !==`undefined`){
    //   const queryString = window.location.search;
    //   const urlParams = new URLSearchParams(queryString);      
    //   if(urlParams){
    //     setFilteredTag(urlParams.get('tag'));
    //   }
    // }
    setLoaded(true);
  }, []);

  return (
    <Layout>
      <SEO title="Blog" />
      {loaded && <Helmet>
        <script type="text/javascript">{'if (window.p5Instance) window.p5Instance.remove();if (window.p5Instance0) window.p5Instance0.remove();if (window.p5Instance1) window.p5Instance1.remove();if (window.p5Instance2) window.p5Instance2.remove();if (window.p5Instance3) window.p5Instance3.remove();if (window.p5Instance4) window.p5Instance4.remove();if (window.p5Instance5) window.p5Instance5.remove();if (window.p5Instance6) window.p5Instance6.remove();if (window.p5Instance7) window.p5Instance7.remove();'}</script>
      </Helmet>}
      <ul className="tagsListing">

        <li>
          <Link to="/blog" className={`link ${!filteredTag ? 'link--active' : ''}`}>All</Link>
        </li>
        {tags.map((tag, i) => {
          return (
            <li key={i}>
              <Link to={'/blog/tag/' + tag.slug} className={`link ${tag.id && tag.id.toString() === filteredTagId && filteredTagId.toString() ? 'link--active' : ''}`}>#{tag.title}</Link>
            </li>
          )
        })}
      </ul>

      <div className="page row blog listing">
        <h1>Blog</h1>
        {filteredTag && <h2>Filtered by #{filteredTag}
          &nbsp;&nbsp;<Link className="link" to={'/blog'}>Clear Filter</Link></h2>}
        {entries.map((entry, i) => {
          return (
            <Item entry={entry} key={i} />
          )
        }
        )}
        <Pager pageContext={pageContext} />
        <Link className="backLink" to="/">Go back to the homepage</Link>

      </div>
    </Layout>
  )
}

export default Blog;
export const query = graphql`query ($skip: Int!, $limit: Int!, $tagId: [Craft_QueryArgument]) {
    craft{
      tags{
        id
        title
        slug
      }
      entries(sectionId: 3, series: ":empty:", limit: $limit, offset: $skip, tags: $tagId) {
        title
        slug
        id
        ... on Craft_blog_external_Entry {
          externalLink
          listingImage{
            url(transform: "listingImage")
          }
          tags{
            slug
            id
            title
          }
         
          blogSummary
        }
        ... on Craft_blog_code_Entry {
          listingImage{
            url(transform: "listingImage")
          }
          tags{
            slug
            id
            title
          }
         
          blogSummary
          blogContent{
            ...BlogContent
          }          
        }

        ... on Craft_blog_posts_Entry {
          listingImage{
            url(transform: "listingImage")
          }
          tags{
            slug
            id
            title
          }
          blogSummary
          blogContent{
            ...BlogContent
          }
        }
      }      
    }
  }


  fragment BlogContent on Craft_blogContent_MatrixField {
    ... on Craft_blogContent_wysiwyg_BlockType{
      wysiwyg
    }

    ... on Craft_blogContent_videoEmbed_BlockType{
      embedCode
    }

  }
  `